import * as React from 'react';
import {Helmet} from 'react-helmet';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl';

const KonfaInnaPage = ({pageContext}) => {
  const {locale} = useIntl();

  const konferencje = pageContext.konferencje
    .filter(konfa => konfa.kind === 'studencka')
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const renderKonfaLink = customPage => {
    const pl = customPage.title.pl;
    const en = customPage.title.en || pl;

    return (
      <Col>
        <a href={`/konferencja/${customPage.slug}`} key={customPage.slug}>
          {locale === 'pl' ? pl : en}
        </a>
      </Col>
    );
  };

  const konfaList = () => {
    return <>{konferencje.map(konfa => renderKonfaLink(konfa))}</>;
  };

  const konfaListEmpty = () => {
    return (
      <>
        <FormattedMessage id="konfa.not-found" />
        <div className="py-xl" />
      </>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>
          {locale === 'pl' ? 'Student Conferences' : 'Konferencje Studenckie'} – Studenckie Koła Naukowe AGH
        </title>
        <meta property="og:title" content={`Konferencje Studenckie – Studenckie Koła Naukowe AGH`} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />

      <div className="konfa">
        <Container>
          <Row>
            <Col>
              <h1>
                <FormattedMessage id="konfa.studencka.title" />
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              {konferencje.length > 0 && konfaList()}
              {konferencje.length === 0 && konfaListEmpty()}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export default KonfaInnaPage;
